<template>
  <div>
    <l-form-model layout="inline">
      <l-form-model-item label="素材分类:">
        <l-select
          v-model="puzzleTypeSelect"
          placeholder="请选择"
          @change="showList(1)"
        >
          <l-select-option
            v-for="(item) in puzzleTypeSelectArr"
            :key="item.value"
            :value="item.value"
          >
            {{ item.name }}
          </l-select-option>
        </l-select>
      </l-form-model-item>
      <l-button
        type="default"
        class="c-theme_add-buttom"
        @click="editRow()"
      >
        新建主题
      </l-button>
    </l-form-model>
    <!-- 列表展示区 -->
    <l-table-common :data-source="tableData" @change="showList">
      <l-table-column
        data-index="index"
        title="序号"
      />
      <l-table-column
        data-index="nameZhcn"
        title="主题名"
      />
      <l-table-column
        data-index="caseCount"
        title="案例数量"
      />
      <l-table-column
        title="是否可见"
      >
        <template slot-scope="scope">
          <div>
            {{ scope.isVisible == 1 ? '显示' : '隐藏' }}
          </div>
        </template>
      </l-table-column>
      <l-table-column
        title="素材分类"
      >
        <template slot-scope="scope">
          <div>
            {{ puzzleTypeStr[scope.puzzleType + ''] || '' }}
          </div>
        </template>
      </l-table-column>
      <l-table-column title="顺序调整">
        <template
          v-if="scope.type !== 0"
          slot-scope="scope"
        >
          <l-button
            v-if="$utils.isUp(scope.index, tableData, 1)"
            class="c-theme_mobile-buttom"
            @click="ganThemeSort(scope.id, 1)"
          >
            上移
          </l-button>
          <l-button
            v-if="$utils.isDown(scope.index, tableData, 1)"
            class="c-theme_mobile-buttom"
            @click="ganThemeSort(scope.id, 2)"
          >
            下移
          </l-button>
        </template>
      </l-table-column>
      <l-table-column title="操作">
        <template
          v-if="scope.type !== 0"
          slot-scope="scope"
        >
          <l-button
            type="link"
            :disabled="scope.nameZhcn == '默认'"
            @click="detail(scope)"
          >
            <div>
              {{ scope.nameZhcn == '默认' ? '无法操作' : '详情' }}
            </div>
          </l-button>
        </template>
      </l-table-column>
      <l-table-column title="删除">
        <template
          v-if="scope.type !== 0"
          slot-scope="scope"
        >
          <l-button
            type="link"
            :disabled="scope.nameZhcn == '默认'"
            @click="themeRemove(scope)"
          >
            <div>
              {{ scope.nameZhcn == '默认' ? '无法删除' : '删除' }}
            </div>
          </l-button>
        </template>
      </l-table-column>
    </l-table-common>
    <!-- 列表展示区结束 -->
    <!-- 新增主题和详情弹跳框 -->
    <div>
      <l-modal
        v-model="visible"
        title="主题信息"
        @ok="handleOk"
      >
        <div class="c-theme_add-theme-bags">
          <div class="c-theme_add-theme-bag">
            <div class="c-theme_add-theme-text">素材分类 :</div>
            <l-radio-group
              v-model="puzzleType"
              class="c-theme_add-theme-input"
              name="radioGroup"
            >
              <l-radio :value="1">
                拼图
              </l-radio>
              <l-radio :value="2">
                拼字
              </l-radio>
            </l-radio-group>
          </div>
          <div class="c-theme_add-theme-bag">
            <div class="c-theme_add-theme-text">主题名称 :</div>
            <l-input v-model="datails.nameZhcn" class="c-theme_add-theme-input" placeholder="请输入" />
          </div>
          <div class="c-theme_add-theme-bag">
            <div class="c-theme_add-theme-text">繁体名称 :</div>
            <l-input v-model="datails.nameZhtw" class="c-theme_add-theme-input" placeholder="请输入" />
          </div>
          <div class="c-theme_add-theme-bag">
            <div class="c-theme_add-theme-text">英文名称 :</div>
            <l-input v-model="datails.nameEn" class="c-theme_add-theme-input" placeholder="请输入" />
          </div>
          <div class="c-theme_add-theme-bag">
            <div class="c-theme_add-theme-text">是否可见 :</div>
            <l-radio-group
              v-model="checkedValue"
              class="c-theme_add-theme-input"
              name="radioGroup"
            >
              <l-radio value="1">
                显示
              </l-radio>
              <l-radio value="0">
                隐藏
              </l-radio>
            </l-radio-group>
          </div>
        </div>
      </l-modal>
    </div>
    <!-- 新增主题和详情弹跳框 结束区 -->
  </div>
</template>

<script>
export default {
  name: 'ThemeManage',
  data() {
    return {
      visible: false, // 详情弹跳框是否显示
      newPage: 1, // 存储当前案例列表页数
      datails: { // 详情弹跳窗数据缓存区
        id: '',
        nameZhcn: '',
        sum: '',
        isVisible: '',
        adjust: '',
        hangle: '',
        nameEn: '',
        nameZhtw: '',
      },
      checkedValue: '', // 详情窗口单选文字（显示/隐藏）
      isid: '', // 详情窗口选中列的id号
      tableData: {
        isLoading: false,
        total: 0,
        page: 1,
        size: 8,
        list: [],
      },
      puzzleTypeSelect: -1, // 类型（1、拼图 2、拼字）
      puzzleTypeSelectArr: [
        {
          value: -1,
          name: '全部'
        },
        {
          value: 1,
          name: '拼图'
        },
        {
          value: 2,
          name: '拼字'
        },
      ],
      puzzleType: '',
      puzzleTypeStr: {
        '1': '拼图',
        '2': '拼字'
      },
    }
  },
  mounted() {
    this.showList(1)
  },
  methods: {
    // 主题列表数据
    async showList(page) {
      page = (page && page.current) || page || this.tableData.page || 1
      this.newPage = page // 存储当前案例列表页数
      this.tableData.isLoading = true
      try {
        const data = await this.$store.dispatch('themes/themeList', {
          page: page,
          size: this.tableData.size,
          puzzleType: this.puzzleTypeSelect === -1 ? '' : this.puzzleTypeSelect,
        })
        this.tableData.isLoading = false
        this.tableData.list = data.list || []
        this.tableData.list.forEach((item, index) => {
          item.index = index + 1 // 添加表单序列号
          if (item.caseCount === 0) { // 如果主题里面案例数量为0，强制默认显示该主题为隐藏
            item.isVisible = 0
          }
        })
        this.tableData.total = data.count
        this.tableData.page = page
      } catch (e) {
        this.tableData = this.$options.data().tableData
      }
    },
    // 新增主题
    editRow() {
      this.isid = ''
      this.datails.nameZhcn = ''
      this.datails.nameEn = ''
      this.datails.nameZhtw = ''
      this.checkedValue = '0'
      this.puzzleType = ''
      this.visible = true
    },

    // 顺序调整
    async ganThemeSort(id, type) {
      await this.$store.dispatch('themes/ganThemeSort', {
        id: id,
        type: type
      })
      this.showList()
    },

    // 新增和详情弹跳框中的确认按钮功能-----------
    async handleOk() { // 弹跳框确认按钮
      if (!this.puzzleType) {
        this.$message.warn('请选择素材分类')
        return
      }
      // 有id值传入的就是运行详情弹跳框确认按钮事件
      // 没id值传入的就是运行增加主题弹跳框确认按钮事件
      await this.$store.dispatch('themes/themeSave', {
        id: this.isid,
        isVisible: this.checkedValue,
        nameZhcn: this.datails.nameZhcn,
        nameZhtw: this.datails.nameZhtw,
        nameEn: this.datails.nameEn,
        puzzleType: this.puzzleType
      })
      this.showList(this.newPage)
      this.puzzleType = ''
      this.visible = false
    },
    // 新增和详情弹跳框中的确认按钮功能结束--------------
    // 详情展示按钮-----------------------------
    detail(e) {
      this.isid = e.id
      this.datails.nameZhcn = e.nameZhcn
      this.checkedValue = e.isVisible.toString()
      this.datails.nameZhtw = e.nameZhtw
      this.datails.nameEn = e.nameEn
      this.visible = true
      this.puzzleType = +e.puzzleType
    },
    // 详情展示按钮结束-----------------------------

    // 删除
    themeRemove(scope) {
      this.$confirm({
        title: `是否删除${scope.nameZhcn}`,
        okText: '确认',
        cancelText: '取消',
        onOk: async () => {
          await this.$store.dispatch('themes/themeRemove', {
            id: scope.id,
          })
          this.showList(1)
        }
      })
    }
  }
}
</script>
<style lang='scss' scoped>
//顺序上下按钮
.c-theme_mobile-buttom{
  margin-right: 5px;
}
/* 详情样式 */
.c-theme_add-theme-bags{
  width: 370px;
  height:180px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: space-around;
  .c-theme_add-theme-bag{
    width: 320px;
    height:45px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .c-theme_add-theme-text{
      width: 100px;
      line-height: 45px;
      text-align: center;
    }
    .c-theme_add-theme-input{
      width: 200px;
      line-height: 45px;
    }
  }
}
/* 详情样式结束 */
</style>
